<template>
  <div
    class="panel-layout"
    :style="[{ background: bgcolor ? bgcolor : '#fff' }]"
  >
    <div class="wb-inner main" :style="[{ padding: padding ? padding : '' }]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['bgcolor', 'padding'],
  name: 'PanelLayout',
};
</script>
<style lang="less" scoped>
.panel-layout {
  width: 100%;
  .wb-inner {
    width: 1600px;
    margin: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 1600px) {
    .wb-inner {
      width: auto;
      padding-left: 40px;
      padding-right: 40px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 1440px) {
    .wb-inner {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
</style>
