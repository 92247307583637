import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      title: '南京元时空地理信息技术有限公司',
    },
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      scrollToTop: true, //跳转路由打开页面直接回到顶部
      title: '南京元时空地理信息技术有限公司',
    },
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      scrollToTop: true,
      title: '新闻动态-南京元时空地理信息技术有限公司',
    },
    component: () => import('../views/News/news.vue'),
  },
  {
    path: '/newsdetail',
    name: 'newsdetail',
    meta: {
      scrollToTop: true,
    },
    component: () => import('../views/News/newsdetail.vue'),
  },
  // {
  //   path: '/editor',
  //   name: 'editor',
  //   meta: {
  //     scrollToTop: true,
  //   },
  //   component: () => import('../views/News/editor.vue'),
  // },
  {
    path: '/business',
    name: 'Business',
    meta: {
      scrollToTop: true,
      title: '业务领域-南京元时空地理信息技术有限公司',
    },
    component: () => import('../views/Business/business.vue'),
  },
  {
    path: '/solution',
    name: 'Solution',
    meta: {
      scrollToTop: true,
      title: '解决方案-南京元时空地理信息技术有限公司',
    },
    component: () => import('../views/Solution/solution.vue'),
  },
  {
    path: '/stepinto',
    name: 'Enterus',
    meta: {
      scrollToTop: true,
      title: '走进元时空-南京元时空地理信息技术有限公司',
    },
    component: () => import('../views/Enterus/enterus.vue'),
  },
  //  {
  //   path: '/notice',
  //   name: 'Notice',
  //   meta: {
  //     scrollToTop: true
  //   },
  //   component: () => import('../views/News/notice.vue')
  // },
  //  {
  //   path: '/notice/news',
  //   name: 'News',
  //   component: () => import('../views/News/news.vue'),
  // },
  // {
  //   path: '/notice/lianxi',
  //   name: 'Lianxi',
  //   component: () => import('../views/News/lianxi.vue'),
  // },{
  //   path: '/notice/student',
  //   name: 'student',
  //   component: () => import('../views/News/student.vue'),
  // },{
  //   path: '/notice/video',
  //   name: 'Video',
  //   component: () => import('../views/News/video.vue'),
  // },{
  //   path: '/notice/details',
  //   name: 'details',
  //   component: () => import('../views/News/Details.vue'),
  // },
  // {
  //   path: '/introduction',
  //   name: 'Introduction',
  //   meta: {
  //     scrollToTop: true
  //   },
  //   component: () => import('../views/Introduction/Introduction.vue'),

  // },
  // {
  //   path: '/introduction/leader',
  //   name: 'Leader',
  //   component: () => import('../views/Introduction/Leader.vue'),
  // },
  // {
  //   path: '/introduction/history',
  //   name: 'History',
  //   component: () => import('../views/Introduction/History.vue'),
  // },
  // {
  //   path: '/teacher',
  //   name: 'Teacher',
  //   meta: {
  //     scrollToTop: true
  //   },
  //   component: () => import('@/views/Teacher/Teacher.vue'),
  // },
  // {
  //   path: '/teacher/guwen',
  //   name: 'Guwen',
  //   component: () => import('../views/Teacher/guwen.vue'),
  // },
  // {
  //   path: '/teacher/fazhan',
  //   name: 'Fazhan',
  //   component: () => import('../views/Teacher/fazhan.vue'),
  // },
  // {
  //   path: '/teacher/xueshu',
  //   name: 'Xueshu',
  //   component: () => import('../views/Teacher/xueshu.vue'),
  // },
  // {
  //   path: '/teacher/zhuanye',
  //   name: 'Zhuanye',
  //   component: () => import('../views/Teacher/zhuanye.vue'),
  // },
  // {
  //   path: '/teacher/chuangye',
  //   name: 'Chuangye',
  //   component: () => import('../views/Teacher/chuangye.vue'),
  // },
  // {
  //   path: '/teacher/teyao',
  //   name: 'Teyao',
  //   component: () => import('../views/Teacher/teyao.vue'),
  // },
  // {
  //   path: '/subject',
  //   name: 'Subject',
  //   meta: {
  //     scrollToTop: true
  //   },
  //   component: () => import('@/views/Subject/Subject.vue'),
  // },
  // {
  //   path: '/subject/fangxiang',
  //   name: 'Fangxiang',
  //   component: () => import('../views/Subject/fangxiang.vue'),
  // },
  // {
  //   path: '/subject/jieshao',
  //   name: 'Jieshao',
  //   component: () => import('../views/Subject/jieshao.vue'),
  // },
  // {
  //   path: '/search',
  //   name: 'Search',
  //   meta: {
  //     scrollToTop: true
  //   },
  //   component: () => import('@/views/Search/Search.vue'),
  // },
  // {
  //   path: '/search/res',
  //   name: 'S_res',
  //   component: () => import('../views/Search/res.vue'),
  // },
  // {
  //   path: '/search/project',
  //   name: 'S_project',
  //   component: () => import('../views/Search/project.vue'),
  // },
  // {
  //   path: '/search/jiaoliu',
  //   name: 'S_jiaoliu',
  //   component: () => import('../views/Search/jiaoliu.vue'),
  // },
  // {
  //   path: '/peiyang',
  //   name: 'Peiyang',
  //   meta: {
  //     scrollToTop: true
  //   },
  //   component: () => import('@/views/Peiyang/peiyang.vue'),
  // },
  // {
  //   path: '/gongzuo',
  //   name: 'Gongzuo',
  //   meta: {
  //     scrollToTop: true
  //   },
  //   component: () => import('@/views/Gongzuo/gongzuo.vue'),
  // },
  // {
  //   path: '/jiaoliu',
  //   name: 'Jiaoliu',
  //   meta: {
  //     scrollToTop: true
  //   },
  //   component: () => import('@/views/Jiaoliu/jiaoliu.vue'),
  // },
  // {
  //   path: '/student',
  //   name: 'Student',
  //   meta: {
  //     scrollToTop: true
  //   },
  //   component: () => import('@/views/Student/student.vue'),
  // },
  // {
  //   path: '/dowload',
  //   name: 'Dowload',
  //   meta: {
  //     scrollToTop: true
  //   },
  //   component: () => import('@/views/Dowload/dowload.vue'),
  // },
  // {
  //   path: '/manager',
  //   name: 'Manager',
  //   meta: {
  //     scrollToTop: true
  //   },
  //   component: () => import('@/views/Manager/manager.vue'),
  // },{
  //   path: '/searchPage',
  //   name: 'searchPage',
  //   meta: {
  //     scrollToTop: true
  //   },
  //   component: () => import('@/views/searchPage.vue'),
  // },
];

//路由导航冗余报错（路由重复）
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  //hash
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  routes,
});

export default router;
