<template>
  <div id="app">
    <Head></Head>
    <transition name="fade-transform" mode="out-in">
      <router-view v-if="isRouterAlive" />
    </transition>
    <Foot></Foot>
  </div>
</template>
<script>
import Head from './views/components/Head.vue';
import Foot from './views/components/Foot.vue';

export default {
  components: {
    Head,
    Foot,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
