<template>
  <PanelLayout :bgcolor="bgcolor" :padding="padding">
    <div class="footer">
      <div class="footone">
        <div class="foot_left">
          <div class="foot_left_main">
            <div style="display: flex">
              <div class="fz40" style="margin-top: 30px; font-weight: 700">
                <i>CONTACT<span style="margin-left: 20px">US</span></i>
              </div>
              <div
                class="fz22"
                style="margin-top: 46px; margin-left: 20px; font-weight: 500"
              >
                <i>联系我们</i>
              </div>
            </div>
            <div class="ltblock" style="margin-top: 15px">
              <i class="el-icon-phone-outline"></i>
              <span>025-52811889</span>
            </div>
            <div class="ltblock" style="margin-top: 10px">
              <i class="el-icon-map-location"></i>
              <span>地址：南京市栖霞区紫东创意园西区F4-2-310</span>
            </div>
            <div class="ltblock" style="margin-top: 10px">
              <i class="el-icon-message"></i>
              <span>电子邮箱：njyskdlxx@sina.com</span>
            </div>
          </div>
        </div>
        <div class="foot_center">
          <div>
            版权所有@南京元时空地理信息技术有限公司&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          |
          <div>&nbsp;&nbsp;&nbsp;&nbsp;苏ICP备2022038078号-1</div>
        </div>
        <div class="foot_right">
          <div style="margin-top: 55px" class="ewm">
            <div>
              <img src="../../assets/yskimg/公众号.png" alt="" />
              <div class="title">公众号</div>
            </div>
            <div style="margin-left: 25px">
              <img src="../../assets/yskimg/QQ.png" alt="" />
              <div class="title">QQ号</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PanelLayout>
</template>

<script>
import PanelLayout from '@/components/layout/PanelLayout';
export default {
  components: {
    PanelLayout,
  },
  data() {
    return {
      bgcolor: '#333',
      padding: '0 0 0 0',
    };
  },

  created() {},

  methods: {},
};
</script>
<style lang="less" scoped>
.footer {
  clear: both;
  width: 100%;
  background: #333;
  color: #fff;

  .footone {
    position: relative;
    // width: 1600px;
    margin: 0 auto;
    height: 220px;
    display: flex;
    justify-content: space-between;
    .foot_center {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      opacity: 0.3;
      font-size: 12px;
    }
    .foot_left {
      width: 50%;
      min-width: 365px;
      height: 100%;

      .ltblock {
        height: 28px;
        display: flex;
        > i {
          color: #208fe1;
          margin-right: 20px;
          font-size: 28px;
        }

        > span {
          display: block;
          height: 100%;
          line-height: 28px;
        }
      }
      .foot_left_main {
        margin-left: 15%;
        height: 100%;
        overflow: hidden;
      }
    }

    .foot_right {
      width: 30%;
      min-width: 385px;
      height: 100%;

      > div {
        height: 28px;
        display: flex;
        > i {
          color: #208fe1;
          margin-right: 20px;
          font-size: 28px;
        }

        > span {
          display: block;
          height: 100%;
          line-height: 28px;
        }
      }

      .ewm {
        display: flex;

        > div {
          width: 130px;
          height: 130px;
          text-align: center;
          border: 1px solid #999;
          position: relative;

          > img {
            margin-top: 12.5px;
            width: 105px;
            height: 105px;
          }

          .title {
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            background: #fff;
            width: 75px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            color: #000;
          }
        }
      }
    }
  }
}
</style>
