<template>
  <div class="header">
    <div class="wb-inner main">
      <div>
        <a href="/"
          ><img
            src="@/assets/yskimg/header/logo.png"
            alt=""
            style="margin-top: 18px"
        /></a>
      </div>
      <el-menu
        router
        @select="selectmenu"
        :default-active="$route.path"
        class="el-menu-demo nav_item"
        mode="horizontal"
      >
        <el-menu-item
          class="menu_item"
          v-for="(item, index) in category"
          :key="index"
          :index="item.path"
        >
          <span class="menu-title-font" slot="title">{{ item.name }}</span>
        </el-menu-item>
      </el-menu>
      <div class="phone"><i class="el-icon-phone-outline"></i>025-52811889</div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
export default {
  inject: ['reload'],
  data() {
    return {
      activeIndex: '/home',
      // 所有一级栏目
      category: [
        { name: '首页', path: '/home' },
        { name: '新闻动态', path: '/news' },
        { name: '业务领域', path: '/business' },
        { name: '解决方案', path: '/solution' },
        { name: '走进元时空', path: '/stepinto' },
      ],
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    selectmenu(index, indexPath) {
      console.log(index, indexPath);
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  background-color: #fff;
  // transition: all 0.7s ease-out 0s;
  // opacity: 0;
  // transform: translateY(-100%);
  // &.showdiv {
  //   transform: none;
  //   opacity: 1;
  // }
  .wb-inner {
    width: 1600px;
    margin: auto;
  }
  @media screen and (max-width: 1600px) {
    .wb-inner {
      width: auto;
      padding-left: 40px;
      padding-right: 40px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 1440px) {
    .wb-inner {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  .main {
    display: flex;
    justify-content: space-between;
    height: 100px;
    margin: 0 auto;
  }

  .nav_item {
    width: 800px;

    .menu_item {
      line-height: 100px;
      height: 100%;
      width: 20%;
    }
  }

  .phone {
    border-radius: 5px;
    background-color: #208de0;
    color: #fff;
    font-weight: 700;
    height: 30px;
    width: 150px;
    text-align: center;
    line-height: 30px;
    margin-top: 35px;

    > i {
      margin-right: 10px;
      font-size: 20px;
      line-height: 30px;
    }
  }
}

//一级菜单样式
.el-menu.el-menu--horizontal {
  border: 0;

  > .el-menu-item {
    text-align: center;
    // border: 0;

    > span {
      padding-bottom: 10px;
      border-block-start-width: 0px;
      border-bottom: 0px solid #208de0;
    }
  }
}

//点击菜单效果
.el-menu--horizontal > .el-menu-item.is-active {
  // border: none;

  > span {
    color: #208de0;
    // border-bottom: 2px solid #208de0;
  }
}
.menu-title-font {
  font-size: 16px;
  font-weight: 400;
  color: #454545;
}
</style>
